import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Lottie from 'lottie-react';
import { Button, Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import confettiAnimation from '../../assets/lotties/111408-confetti.json';
import successAnimation from '../../assets/lotties/131518-success-confirmation.json';
import sceneGptNoBg from "../../assets/img/scenegpt_no_bg.png";

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(165deg, #1c1c1f 0%, #141416 100%)',
  color: '#ffffff',
  padding: theme.spacing(3),
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
}));

const GlassCard = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '24px',
  padding: theme.spacing(4),
  maxWidth: '400px',
  width: '100%',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  position: 'relative',
  zIndex: 2,
}));

const SuccessText = styled(Typography)(({ theme }) => ({
  color: '#4CAF50',
  fontSize: '1.25rem',
  fontWeight: 600,
  letterSpacing: '0.05em',
  marginBottom: '8px',
  background: 'linear-gradient(45deg, #4CAF50, #45a049)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textShadow: '0 2px 4px rgba(76, 175, 80, 0.2)',
}));

const PaymentSuccessful: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 8000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <StyledContainer>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <GlassCard>
            {/* Success Animation */}
            <Box sx={{ width: '120px', margin: '0 auto', marginBottom: 2 }}>
              <Lottie animationData={successAnimation} loop={false} />
            </Box>

            {/* Success Text */}
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
            >
              <SuccessText variant="h6">
                Payment Successful
              </SuccessText>
            </motion.div>

            {/* Success Circle with Mascot */}
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                background: "rgba(76, 175, 80, 0.1)",
                border: "2px solid #4CAF50",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "24px",
                position: "relative",
                margin: "24px auto",
              }}
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2 }}
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  background: "linear-gradient(45deg, #4CAF50, #45a049)",
                }}
              />
              <img 
                src={sceneGptNoBg} 
                alt="SceneGPT" 
                style={{
                  position: "absolute",
                  width: "80px",
                  height: "80px",
                  objectFit: "contain"
                }}
              />
            </motion.div>

            {/* Content */}
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
              style={{
                textAlign: "center",
                maxWidth: "100%",
                zIndex: 1,
              }}
            >
              <Typography 
                variant="h5" 
                component="h1"
                sx={{ 
                  fontSize: "2.5rem",
                  marginBottom: "1rem",
                  fontWeight: 600,
                  background: 'linear-gradient(45deg, #4CAF50, #45a049)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                Welcome to the Club
              </Typography>

              <Typography 
                variant="body1" 
                sx={{ 
                  color: "#ffffff",
                  fontSize: "1.125rem",
                  lineHeight: 1.5,
                  marginBottom: "1rem",
                  opacity: 0.9,
                }}
              >
                You just unlocked something special.
              </Typography>

              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.8 }}
                style={{
                  background: "rgba(76, 175, 80, 0.1)",
                  backdropFilter: "blur(10px)",
                  borderRadius: "12px",
                  padding: "16px",
                  border: "1px solid rgba(76, 175, 80, 0.2)",
                }}
              >
                <Typography 
                  variant="body2"
                  sx={{ 
                    color: "#ffffff",
                    fontSize: "0.875rem",
                    margin: 0,
                    opacity: 0.8,
                  }}
                >
                  Redirecting you to your enhanced experience...
                </Typography>
              </motion.div>
            </motion.div>
          </GlassCard>
        </motion.div>
      </AnimatePresence>

      {/* Confetti Animation */}
      <Box sx={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        pointerEvents: 'none',
        opacity: 0.7
      }}>
        <Lottie animationData={confettiAnimation} loop={true} />
      </Box>

      {/* Subtle particles */}
      {[...Array(8)].map((_, i) => (
        <motion.div
          key={i}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 0.6 }}
          transition={{
            delay: i * 0.1,
            duration: 0.5,
            repeat: Infinity,
            repeatDelay: 2,
          }}
          style={{
            position: "absolute",
            width: "4px",
            height: "4px",
            borderRadius: "50%",
            background: i % 2 === 0 ? "#4CAF50" : "#ffffff",
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            zIndex: 1,
          }}
        />
      ))}
    </StyledContainer>
  );
};

export default PaymentSuccessful;