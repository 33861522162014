import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import CheckoutButton from "./CheckoutButton";
import LastChanceModal from "./LastChanceModal";
import { User } from "../../types/UserType";
import "../../assets/css/checkoutmodal.css";
import "../../assets/css/lastchancemodal.css";
import posthog from "posthog-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faBan,
  faFire,
  faBrain,
  faMicrophone,
  faRocket,
  faBolt,
  faFilm,
  faPalette,
  faBullseye,
  faEnvelope,
  faGift
} from "@fortawesome/free-solid-svg-icons";

// Import images
import followupMaxFeatureLite from "../../assets/img/followup_max_feature_lite.png";
import unlockcharMaxFeature from "../../assets/img/unlockchar_max_feature.png";
import rolesMaxFeature from "../../assets/img/roles_max_feature.png";
import dailymailMaxFeature from "../../assets/img/dailymail_max_feature.png";
import sceneGptNoBg from "../../assets/img/scenegpt_no_bg.png";
import paywallHeader from "../../assets/img/paywall_header.png";
import mood_max_feature from "../../assets/img/mood_max_feature.png";
import unlockchar_max_feature from "../../assets/img/unlockchar_max_feature.png";

gsap.registerPlugin(Draggable);

interface FeatureSlide {
  image: string;
  title: string;
  icon: any;
  showMascot?: boolean;
}

export enum ProductType {
  SUBSCRIPTION = "subscription",
  ONE_TIME = "one_time"
}

export enum Products {
  PREMIUM = "premium",
  DAILY_SUBSCRIPTION = "daily_subscription",
  CREDITS_20 = "credits_20",
  CREDITS_50 = "credits_50",
  CREDITS_100 = "credits_100",
  CREDITS_200 = "credits_200"
}

export const CREDIT_PACKAGES = [
  { credits: 20, price: 1.99, product: Products.CREDITS_20 },
  { credits: 50, price: 4.99, product: Products.CREDITS_50 },
  { credits: 100, price: 8.99, product: Products.CREDITS_100 },
  { credits: 200, price: 15.99, product: Products.CREDITS_200 }
];

export type PlanType = "monthly" | "annual" | "annual-reduced" | "daily_subscription" | Products;


const FEATURE_SLIDES: FeatureSlide[] = [
  {
    image: paywallHeader,
    title: "Get sceneXtras MAX",
    icon: faBolt,
    showMascot: true
  },
  {
    image: unlockchar_max_feature,
    title: "Unlock millions of characters",
    icon: faFilm
  },
  {
    image: mood_max_feature,
    title: "Change Moods",
    icon: faPalette
  },
  {
    image: rolesMaxFeature,
    title: "Roleplay as Anyone",
    icon: faBullseye
  },
  {
    image: dailymailMaxFeature,
    title: "Daily Mails",
    icon: faEnvelope
  }
];

const QUICK_FEATURES = [
  { icon: faBan, text: "No Ads" },
  { icon: faRocket, text: "Priority" },
  { icon: faFire, text: "Beta" },
  { icon: faBrain, text: "Memory" },
  { icon: faMicrophone, text: "Voices" }
];

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  displaySource?: string;
}

const CheckoutModal: React.FC<CheckoutModalProps> = ({ isOpen, onClose, user, displaySource }) => {
  const [selectedPlan, setSelectedPlan] = useState<PlanType>("monthly");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showLastChance, setShowLastChance] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number>(300); // 5 minutes
  const [offerType, setOfferType] = useState<"single-day" | "yearly">("single-day");
  const modalRef = useRef<HTMLDivElement>(null);
  const slideContainerRef = useRef<HTMLDivElement>(null);
  const slideRefs = useRef<(HTMLDivElement | null)[]>([]);
  const dragInstance = useRef<Draggable>();
  const autoAdvanceRef = useRef<gsap.core.Tween | null>(null);
  const currentSlideRef = useRef(0);
  const lastChanceTimer = useRef<NodeJS.Timeout>();
  const activityTimer = useRef<NodeJS.Timeout>();
  const lastActivityTime = useRef<number>(Date.now());
  const checkoutButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isOpen && modalRef.current && slideContainerRef.current) {
      gsap.fromTo(modalRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.3, ease: "power2.out" }
      );

      if (dragInstance.current) {
        dragInstance.current.kill();
      }

      const slideWidth = slideContainerRef.current.offsetWidth;

      dragInstance.current = Draggable.create(slideContainerRef.current, {
        type: "x",
        inertia: true,
        bounds: {
          minX: -slideWidth * (FEATURE_SLIDES.length - 1),
          maxX: 0
        },
        snap: {
          x: (endValue) => Math.round(endValue / slideWidth) * slideWidth
        },
        onDragEnd: function() {
          const newIndex = Math.abs(Math.round(this.endX / slideWidth));
          setCurrentSlide(newIndex);
        }
      })[0];

      autoAdvanceRef.current = gsap.to({}, {
        duration: 4,
        repeat: -1,
        onRepeat: () => {
          const nextSlide = (currentSlideRef.current + 1) % FEATURE_SLIDES.length;
          gsap.to(slideContainerRef.current, {
            xPercent: -nextSlide * 100,
            duration: 0.8,
            ease: "power2.inOut",
            overwrite: true
          });
          setCurrentSlide(nextSlide);
          currentSlideRef.current = nextSlide;
        }
      });

      // Reset timers when modal opens
      setTimeLeft(300);
      lastActivityTime.current = Date.now();

      // Start activity monitoring
      activityTimer.current = setInterval(() => {
        const inactiveTime = Date.now() - lastActivityTime.current;
        if (inactiveTime >= 1800000) { // 30 minutes
          setShowLastChance(true);
          lastActivityTime.current = Date.now();
        }
      }, 60000); // Check every minute

      return () => {
        autoAdvanceRef.current?.kill();
        dragInstance.current?.kill();
        if (activityTimer.current) clearInterval(activityTimer.current);
        if (lastChanceTimer.current) clearInterval(lastChanceTimer.current);
      };
    }
  }, [isOpen]);

  const handlePlanSelect = (plan: PlanType) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedPlan(plan);
    posthog.capture("plan_selected", { plan });
  };

  const handleClose = () => {
    // Randomly choose between trial and yearly offer
    setOfferType(Math.random() < 0.5 ? "single-day" : "yearly");
    setShowLastChance(true);
    // console.log("Closing checkout modal");

    // Start countdown timer when last chance modal shows
    lastChanceTimer.current = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(lastChanceTimer.current);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleLastChanceClose = () => {
    setShowLastChance(false);
    clearInterval(lastChanceTimer.current);
    onClose(); // Close the main modal too
  };

  const handleUserActivity = () => {
    lastActivityTime.current = Date.now();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="checkout-modal-overlay" onClick={onClose}>
        <div
          ref={modalRef}
          className="checkout-modal"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            background: "#1c1c1f",
            position: "relative",
            overflow: "hidden"
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              height: "50%",
              background: "radial-gradient(circle at bottom, rgba(255, 149, 0, 0.08) 0%, rgba(255, 149, 0, 0) 70%)",
              pointerEvents: "none",
              zIndex: 1
            }}
          />
          <div className="modal-header">
            <button className="close-button" onClick={handleClose}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>

          <div className="feature-slideshow">
            <div className="hero-content">
              {FEATURE_SLIDES[currentSlide].showMascot && (
                <div className="mascot-container">
                  <img src={sceneGptNoBg} alt="SceneGPT" className="mascot" />
                </div>
              )}
              <div className="slides-container" ref={slideContainerRef}>
                {FEATURE_SLIDES.map((slide, index) => (
                  <div
                    key={index}
                    ref={el => slideRefs.current[index] = el}
                    className={`slide ${index === currentSlide ? "active" : ""}`}
                  >
                    <div className="slide-content">
                      <img
                        src={slide.image}
                        alt={slide.title}
                        loading={index === 0 ? "eager" : "lazy"}
                        draggable="false"
                      />
                      <div className="feature-highlight">
                        <span className="feature-name">{slide.title}</span>
                        <span className="feature-arrow">›</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="feature-icons-container">
              <div className="feature-icons">
                {FEATURE_SLIDES.map((slide, index) => (
                  <button
                    key={index}
                    className={`feature-icon-button ${index === currentSlide ? "active" : ""}`}
                    onClick={() => {
                      if (!slideContainerRef.current) return;
                      autoAdvanceRef.current?.pause();
                      gsap.to(slideContainerRef.current, {
                        xPercent: -index * 100,
                        duration: 0.5,
                        ease: "power2.inOut",
                        overwrite: true,
                        onComplete: () => {
                          setCurrentSlide(index);
                          currentSlideRef.current = index;
                          autoAdvanceRef.current?.restart();
                        }
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={slide.icon}
                      style={{
                        color: "#ffffff",
                        fontSize: "20px",
                        opacity: index === currentSlide ? 1 : 0.7
                      }}
                    />
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="plan-section" style={{ position: "relative", zIndex: 2 }}>
            <div className="brand">
              <img src={`${process.env.PUBLIC_URL}/full_logo.svg`} alt="SceneXtras" className="scenextras-logo" />
              <span className="max-badge">MAX</span>
            </div>

            <h2 style={{
              fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif",
              fontSize: "20px",
              fontWeight: "600",
              textAlign: "center",
              color: "#ffffff",
              margin: "0 0 16px 0",
              letterSpacing: "-0.02em"
            }}>
              Unlock more fun
            </h2>

            <div className="quick-features">
              {QUICK_FEATURES.map((feature, index) => (
                <div key={index} className="quick-feature">
                  <FontAwesomeIcon icon={feature.icon} className="feature-icon" />
                  <span className="feature-text">{feature.text}</span>
                </div>
              ))}
            </div>

            <div className="plan-options">
              {/* Original Variant - Commented for A/B Testing
              <button
                className={`plan-option monthly ${selectedPlan === "monthly" ? "selected" : ""}`}
                onClick={handlePlanSelect("monthly")}
              >
                <div className="plan-details">
                  <span className="plan-name">Monthly Plan</span>
                  <span className="price">$9.99/month</span>
                </div>
                <div className="price-details">
                  <span className="weekly-price">$2.31/week</span>
                </div>
              </button>

              <button
                className={`plan-option annual ${selectedPlan === "annual" ? "selected" : ""}`}
                onClick={handlePlanSelect("annual")}
              >
                <div className="savings-badge">Save 42%</div>
                <div className="plan-details">
                  <span className="plan-name">Annual Plan</span>
                  <span className="price">$69.99/year</span>
                </div>
                <div className="price-details">
                  <span className="weekly-price">$1.35/week</span>
                </div>
              </button>
              */}

              {/* New Variant */}
              <button
                className={`plan-option monthly ${selectedPlan === "monthly" ? "selected" : ""}`}
                onClick={handlePlanSelect("monthly")}
              >
                <div className="plan-details">
                  <span className="plan-name">Monthly Plan</span>
                  <span className="price">$9.99/month</span>
                </div>
                <div className="price-details">
                  <span className="weekly-price">$2.31/week</span>
                </div>
              </button>
            </div>

            <CheckoutButton
              ref={checkoutButtonRef}
              user={user}
              className="upgrade-button2"
              plan={selectedPlan}
              onButtonClick={() => posthog.capture("upgrade_clicked", { plan: selectedPlan })}
              displaySource={displaySource}
            >
              {displaySource === "export" ? "Try it free!" : " Upgrade Now!"}
            </CheckoutButton>

            <p className="secure-text">
              🔒 Secure payment • Cancel anytime
            </p>

            {/*/!* New Variant *!/*/}
            {/*<p className="secure-text">*/}
            {/*  3 Day free trial • No payment due now*/}
            {/*  <br />*/}
            {/*  Cancel anytime*/}
            {/*</p>*/}
          </div>
        </div>
      </div>

      <LastChanceModal
        isOpen={showLastChance}
        onClose={handleLastChanceClose}
        onAccept={() => {
          setShowLastChance(false);
          if (offerType === "single-day") {
            setSelectedPlan("daily_subscription");
            setTimeout(() => {
              checkoutButtonRef.current?.click();
            }, 500);

            posthog.capture("trial_purchase", { source: "last_chance" });
          } else {
            setSelectedPlan("annual-reduced");
            setTimeout(() => {
              checkoutButtonRef.current?.click();
            }, 500);
            posthog.capture("yearly_purchase", { source: "last_chance" });
          }
        }}
        timeLeft={timeLeft}
        offerType={offerType}
      />
    </>
  );
};

export default CheckoutModal;
