import React, { useEffect, useRef, useState } from "react";
import { User } from "../../types/UserType";
import "../../../src/assets/css/account.css";
import { getWithoutExpiry, RECENT_CHARACTERS_KEY, setWithoutExpiry } from "../../helper/storageUtils";
import { useNavigate } from "react-router-dom";
import { sendExportMessage, sendReferralCode } from "../../api/apiClient";
import { Cast } from "../../types/CastType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faSquareXTwitter, faFacebookF, faWhatsapp, faTelegram, faReddit } from "@fortawesome/free-brands-svg-icons";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { faStar, faFire } from "@fortawesome/free-solid-svg-icons";
// import { faCheck, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
// import { faCopy } from "@fortawesome/free-regular-svg-icons";
// import { applyRetroactiveReferral } from "../../api/authClient";
import posthog from "posthog-js";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import gsap from "gsap";
import Loading from "../miscs/Loading";
import log from "loglevel";
import { toast } from "react-toastify";
import UserDetailsManager from "../modals/UserDetailsModal";
import UnsubscribeButton from "../payments/UnsubscribeButton";
import { Message } from "../chat/InnerChat";
import { Movie } from "../../types/MovieType";
import "../../../src/assets/css/upgradebtn.css";
import CheckoutModal from "../payments/CheckoutModal";
import { useBackgroundSync } from "../../hooks/BackgroundSyncHook";
import { getUserConfig, NotificationsConfig, updateUserConfig } from "../../api/authClient";
import { Switch } from "@mui/material";
import * as Sentry from "@sentry/react";
import {
  AutoFixHigh,
  Castle,
  Psychology,
  AutoStories,
  Flare,
  TheaterComedy,
  Pets,
  EmojiSymbols,
  WorkspacePremium,
  Star,
  EmojiEvents,
  LocalFireDepartment
} from "@mui/icons-material";
import { SvgIconComponent } from "@mui/icons-material";
import UserDetailsManagerSimple from "../modals/UserDetailsModalSimple";


interface ProfilePageProps {
  user: User | null;
  signOut: () => void;
  session?: Session;
  supabase: SupabaseClient;
}

interface ReferralFormInputs {
  email: string;
  referralCode: string;
}

interface ReferralCredits {
  earned: number;
  available: number;
}

Modal.setAppElement("#root");


const Account: React.FC<ProfilePageProps> = ({ user, signOut, session, supabase }) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState<{ [key: string]: Message[] }>({});
  const [cast, setCast] = useState<Cast[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ReferralFormInputs>();
  const [referralCredits, setReferralCredits] = useState<ReferralCredits>({
    earned: 0,
    available: 0
  });
  const [referral_code_type, setReferralCodeType] = useState<string>("");
  const [friend_email, setFriendEmail] = useState<string>("");
  const [showAllHistory, setShowAllHistory] = useState(false);
  const [notificationsConfig, setNotificationsConfig] = useState<NotificationsConfig>({
    daily_notifications: false
    // movie_notifications: false,
    // anime_notifications: false,
    // series_notifications: false,
    // actors_notifications: false,
  });
  const [isOnboarded, setIsOnboarded] = useState<boolean>(false);
  // console.log(notificationsConfig);
  const hasSyncedRef = useRef(false);
  const { manualSync, isSyncing } = useBackgroundSync({ manualSyncOnly: true });
  const [isLoading, setIsLoading] = useState(false);



  const displaySubscribeModal = () => {
    posthog.capture("display_subscribe_modal");
    setIsModalOpen(true);
  };

  const handleClick = async () => {
    try {
      await manualSync();
      getMessagesFromLocalStorage();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Sync failed:", error);
    }
  };

  useEffect(() => {
    getMessagesFromLocalStorage();
  }, []);

  const getMessagesFromLocalStorage = () => {
    const messagesJson = getWithoutExpiry("messages");
    if (messagesJson) {
      setMessages(JSON.parse(messagesJson));
    } else {
      setMessages({});
    }
  };

  const logout = () => {
    supabase.auth.signOut();
    setWithoutExpiry("logged_in", false);
    signOut();
    posthog.reset();
    sessionStorage.clear();
    localStorage.clear();
    navigate("/profile");
  };

  const openModal = () => {
    // Function to open the modal
    setIsModalOpen(true);
    gsap.to(".modal", { opacity: 1, duration: 0.5 });
  };

  const closeModal = () => {
    // Function to close the modal
    gsap.to(".modal", { opacity: 0, duration: 0.5, onComplete: () => setIsModalOpen(false) });
  };

  const onSubmit = async (data: ReferralFormInputs) => {
    if (friend_email) {
      log.info(data);
      try {
        await sendReferralCode(friend_email);
        toast.success("Referral successfully sent");
        closeModal();
        gsap.to(".referrals-form-submit", { scale: 1.1, duration: 0.2, yoyo: true, repeat: 1 });
      } catch (e) {
        Sentry.captureException(e);
        log.error(e);
        if (e instanceof Error) {
          toast.error(e.message);
        } else {
          toast.error("Failed to send referral code");
        }
      }
    }
  };

  const handleCopyClick = () => {
    if (!user) {
      return;
    }

    navigator.clipboard
      .writeText(`${user.referral_code}`)
      .then(() => {
        alert("Referral link copied to clipboard");
        gsap.to(".referrals-copy-button", { scale: 1.1, duration: 0.2, yoyo: true, repeat: 1 });
      })
      .catch((err) => {
        log.error("Could not copy text: ", err);
      });
  };

  const handleTwitterClick = () => {
    if (user) {
      posthog.capture("twitter_share_clicked");
      const tweetText = `Unveil the future of immersive storytelling. Visit SceneXtras and use code '${user.referral_code}' upon signup.`;
      const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        tweetText
      )}`;
      window.open(
        twitterURL,
        "_blank",
        "width=600,height=400,noopener,noreferrer"
      );
      gsap.to(".referrals-twitter-icon", { rotation: 360, duration: 0.5 });
    }
  };

  // Remove or comment out the entire handleApplyReferral function
  /*
  function handleApplyReferral() {
    posthog.capture("referral_code_applied");
    applyRetroactiveReferral(referral_code_type).then((response) => {
      if (response) {
        posthog.capture("referral_code_applied_success");
        alert("Referral code applied successfully!");
        window.location.reload();
      } else {
        posthog.capture("referral_code_applied_failure");
        alert("Invalid referral code");
      }
      gsap.to(".referrals-apply-button", { scale: 1.1, duration: 0.2, yoyo: true, repeat: 1 });
    });
  }
  */

  const getProfileIcon = (seed: string): SvgIconComponent => {
    const icons = [
      AutoFixHigh,
      Castle,
      Psychology,
      AutoStories,
      Flare,
      TheaterComedy,
      Pets,
      EmojiSymbols,
      WorkspacePremium
    ];

    const hashCode = seed.split("").reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

    const iconIndex = Math.abs(hashCode) % icons.length;
    return icons[iconIndex];
  };

  const getStreakInfo = (streakCount: number): { title: string; Icon: SvgIconComponent } => {
    const streakLevels = [
      { title: "Rookie", Icon: Star, day: 1 },
      { title: "Plot twister", Icon: EmojiEvents, day: 2 },
      { title: "Co-star", Icon: LocalFireDepartment, day: 3 },
      { title: "Supporting Act", Icon: Psychology, day: 4 },
      { title: "Canon Master", Icon: AutoFixHigh, day: 5 }
    ];

    const currentLevel = streakLevels.find(level => level.day === streakCount) || streakLevels[0];
    return currentLevel;
  };

  const truncateName = (name: string) => {
    if (!name) return "";
    const words = name.split(" ");
    return words.slice(0, Math.min(3, words.length)).join(" ");
  };

  const getTimeAgo = (timestamp: string | undefined): string => {
    if (!timestamp) return "Unknown time ago";

    const now = new Date();
    const messageTime = new Date(timestamp);
    const diffInMinutes = Math.floor((now.getTime() - messageTime.getTime()) / (1000 * 60));

    if (diffInMinutes < 1) {
      return "Just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""} ago`;
    } else if (diffInMinutes < 1440) { // Less than 24 hours
      const hours = Math.floor(diffInMinutes / 60);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffInMinutes / 1440);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  };

  const historyToShow = Object.entries(messages)
    .sort(([, messagesA], [, messagesB]) => {
      const lastMessageA = messagesA[messagesA.length - 1];
      const lastMessageB = messagesB[messagesB.length - 1];
      const timestampA = lastMessageA?.timestamp ? new Date(lastMessageA.timestamp).getTime() : 0;
      const timestampB = lastMessageB?.timestamp ? new Date(lastMessageB.timestamp).getTime() : 0;
      return timestampB - timestampA;
    })
    .map(([character, characterMessages]) => {
      const lastMessage = characterMessages[characterMessages.length - 1];
      return {
        character,
        lastMessageTime: lastMessage?.timestamp ? getTimeAgo(lastMessage.timestamp) : "Unknown",
        profileImage: lastMessage?.profileImage || `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`
      };
    })
    .slice(0, showAllHistory ? undefined : 5);

  const handleCharacterClick = (character: string) => {
    const recentCharacters = getWithoutExpiry(RECENT_CHARACTERS_KEY) || [];

    // Find the character in the recentCharacters array by matching the character's name
    const foundCharacter = recentCharacters.find((c: Cast) => c.character === character);

    if (foundCharacter) {
      // Navigate to the chat page if the character exists in recent characters
      navigate("/chat", {
        state: {
          character: foundCharacter,
          chat_type: "carousel",
          movie: { title: foundCharacter.from_movie, cast: [foundCharacter] } as Movie,
          cast: [foundCharacter]
        }
      });

    }
  };


  useEffect(() => {
    fetchUserConfig();
  }, []);

  const fetchUserConfig = async () => {
    try {
      const response = await getUserConfig(session);
      if (response?.config) {
        setIsOnboarded(response.config.is_onboarded ?? false);
        setNotificationsConfig(response.config.notifications ?? {});
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleOnboardingComplete = async () => {
    try {
      await updateUserConfig(session, {
        config: { is_onboarded: true },
        notifications: notificationsConfig ?? {}
      });
      setIsOnboarded(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  };


  const handleNotificationToggle = async (key: keyof NotificationsConfig) => {
    const newConfig = { ...notificationsConfig, [key]: !notificationsConfig[key] };
    setNotificationsConfig(newConfig);

    try {
      await updateUserConfig(session, {
        config: {},
        notifications: newConfig
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error updating user config:", error);
      setNotificationsConfig({ ...notificationsConfig });
    }
  };

  if (!user) {
    return <Loading timeoutRoute="/" timeoutDuration={8000} />;
  }

  return (<>
      {isLoading && <Loading />}
    <div className="settings-container">
      <div className="settings-header">
        <div className="user-profile">
          <div className="profile-content">
            <div className="profile-avatar-container">
              {/*<button onClick={() => exportApiCalLWrapper("Iron Man / Tony Stark")}>*/}
              {/*  CLICK*/}
              {/*</button>*/}
              <div className="profile-image">
                {(() => {
                  const IconComponent = getProfileIcon((user as any).id || user.email || "default");
                  return <IconComponent fontSize="large" style={{ color: "white" }} />;
                })()}
              </div>
              <div className="streak-level">
                <div className="streak-badge">
                  {(() => {
                    const { Icon, title } = getStreakInfo(user?.streak || 0);
                    return (
                      <>
                        <Icon fontSize="small" style={{ color: "#FFB800", marginRight: "4px" }} />
                        <span>{title}</span>
                      </>
                    );
                  })()}
                </div>
              </div>
            </div>
            <div className="user-info">
              <h2>{user.name}</h2>
              <p>{user.email}</p>
              {!isOnboarded && (
                <UserDetailsManager
                  session={session}
                  isOnboarded={isOnboarded}
                  onComplete={handleOnboardingComplete}
                  user={user}
                  setIsLoading={setIsLoading}
                />)}
              <UserDetailsManagerSimple session={session} setIsLoading={setIsLoading}/>
            </div>
          </div>
        </div>
      </div>
      <div className="settings-section">
        <h3>Communication</h3>
        {notificationsConfig && Object.entries(notificationsConfig)
          .filter(([key]) => key === "daily_notifications")
          .map(([key, value]) => (
            <div key={key} className="communication-setting">
              <div className="communication-text">
                <div className="setting-title">Daily Emails</div>
                <div className="setting-description">Characters can send you funny daily mail while you are away</div>
              </div>
              <Switch
                checked={value}
                onChange={() => handleNotificationToggle(key as keyof NotificationsConfig)}
                color="primary"
              />
            </div>
          ))
        }
      </div>

      <div className="settings-body">
        <div className="settings-section">
          <ul className="settings-list">
            <li className="settings-item">
              <span>SceneXtras MAX 🍿 </span>
              {user && user.isPremium === "premium" ? (
                <div className="subscription-status">
                  <span></span>
                  {user.unsubscribed ? (<span>Unsubscribed</span>) : <UnsubscribeButton user={user} />}
                </div>
              ) : (
                <span>Standard User</span>
              )}
            </li>
            {/*{user && user.premium_expiration !== "NONE" && user.isPremium === "premium" && (*/}
            {/*  <li className="settings-item">*/}
            {/*    <span>Premium Expiration</span>*/}
            {/*    <span>{user.premium_expiration}</span>*/}
            {/*  </li>*/}
            {/*)*/}
            {/*}*/}
            {user && user.isPremium !== "premium" && (
              <>
                <li className="settings-item">
                  <span>Credits Earned</span>
                  <span>{user.quota_earned}</span>
                </li>
                <li className="settings-item">
                  <span>Credits Available</span>
                  <span>{user.remainingQuota}</span>
                </li>
              </>
            )}
          </ul>
        </div>
        {
          user && user.isPremium !== "premium" && (
            <div className="settings-section">
              <h3>Upgrade to Pro</h3>
              <button className="upgrade-button" onClick={() => displaySubscribeModal()}>
                <span className="upgrade-button-text">Upgrade</span>
              </button>
              <CheckoutModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                user={user}
                displaySource={"account"}
              />
            </div>
          )}
        <div className="settings-section referrals-section">
          <h3>
            🎁
            Share sceneXtras
          </h3>
          <p className="referral-bonus-text">
            Refer friends and they skip the waitlist
          </p>
          <div className="referrals-content">
            <div className="referral-card">
              <div className="card-content">
                <div className="share-input-group">
                  <input
                    className="modern-input"
                    name="email"
                    placeholder="friend@email.com"
                    value={friend_email}
                    onChange={(e) => setFriendEmail(e.target.value)}
                  />
                  <button
                    className="modern-submit-button"
                    type="submit"
                    disabled={!friend_email}
                    onClick={handleSubmit(onSubmit)}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
                {errors.email && <p className="referrals-form-error">This field is required</p>}

                <div className="social-share-buttons">
                  {[
                    {
                      icon: faReddit,
                      url: "https://www.reddit.com/submit?url=https://scenextras.com&title=",
                      color: "#FF4500",
                      priority: 1
                    },
                    {
                      icon: faSquareXTwitter,
                      url: "https://twitter.com/intent/tweet?text=",
                      color: "#1DA1F2",
                      priority: 2
                    },
                    {
                      icon: faFacebookF,
                      url: "https://www.facebook.com/sharer/sharer.php?u=https://scenextras.com&quote=",
                      color: "#4267B2",
                      priority: 3
                    },
                    {
                      icon: faWhatsapp,
                      url: "https://wa.me/?text=",
                      color: "#25D366",
                      priority: 4
                    },
                    {
                      icon: faTelegram,
                      url: "https://t.me/share/url?url=https://scenextras.com&text=",
                      color: "#0088cc",
                      priority: 5
                    }
                  ].sort((a, b) => a.priority - b.priority)
                    .map((platform, index) => (
                      <button
                        key={index}
                        className="social-icon-button"
                        onClick={() => {
                          const character = Object.keys(messages)[0];
                          const lastUserMessage = messages[character]?.find(msg => msg.sender === "user")?.content;
                          const text = `I used this bot to ask ${character || "my favorite character"} "${lastUserMessage || "something outrageous"}"! Here is their reaction https://scenextras.com`;
                          window.open(`${platform.url}${encodeURIComponent(text)}`, "_blank");
                          posthog.capture(`${platform.icon.iconName}_share_clicked`);
                        }}
                        style={{ "--hover-color": platform.color } as React.CSSProperties}
                      >
                        <FontAwesomeIcon icon={platform.icon} />
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="settings-section history-section">
          <h3>Recent Chats</h3>
          <ul className="history-list">
            {historyToShow.map(({ character, lastMessageTime, profileImage }, index) => (
              <li key={character} className="history-item" onClick={() => handleCharacterClick(character)}>
                <div className="character-profiles">
                  <img
                    className="character-avatar-small-acc"
                    src={profileImage}
                    alt={`${character} Avatar`}
                  />
                </div>
                <div className="show-info">
                  <div className="show-name">
                    {character.replace("(voice)", "")}
                  </div>
                </div>
                <div className="time-ago-container">
                  <div className="time-ago">{lastMessageTime}</div>
                  {index === 0 && <div className="notification-count">1</div>}
                </div>
              </li>
            ))}
          </ul>
          {Object.keys(messages).length > 5 && (
            <button className="view-more-button" onClick={() => setShowAllHistory(!showAllHistory)}>
              {showAllHistory ? "Show Less" : "View More"}
            </button>
          )}

          <button className="view-more-button fetch-history-button" onClick={handleClick}>Fetch History
          </button>
        </div>
      </div>
      <div className="settings-footer">
        <button className="sign-out-button" onClick={logout}>Sign out</button>
      </div>
    </div>
    </>
  );
};

export default Account;