import React, { useState, forwardRef } from "react";
import { createCheckoutSession } from "../../api/apiClient";
import log from "loglevel";
import { User } from "../../types/UserType";
import { loadStripe } from "@stripe/stripe-js";
import posthog from "posthog-js";
import { PlanType } from "./CheckoutModal";
import { useNavigate } from "react-router-dom";

type CheckoutButtonProps = {
  user: User;
  className?: string;
  children?: React.ReactNode;
  plan: PlanType;
  onButtonClick: () => void;
  displaySource?: string;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

const CheckoutButton = forwardRef<HTMLButtonElement, CheckoutButtonProps>(
  ({ user, className, children, plan, onButtonClick, displaySource }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleClick = async () => {
      if (displaySource && displaySource != "export") {
        posthog.capture("user clicked upgrade button");
        const rootUrl = window.location.origin;
        const session = await createCheckoutSession(rootUrl, user.email, plan);
        const stripe = await stripePromise;
        if (!stripe) {
          log.error("Stripe.js has not been loaded");
          throw new Error("Stripe.js failed to load");
        }
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id
        });

        if (error) {
          posthog.capture("error upgrading", { error });
          log.error("Error:", error);
        }
      }else{
        navigate("/");
      }
    };

    return (
      <button
        ref={ref}
        onClick={handleClick}
        disabled={isLoading}
        className={`upgrade-button2 ${className || ""}`}
      >
        <div className="text">
          {isLoading ? "Processing..." : children}
        </div>
        <div className="shimmer"></div>
      </button>
    );
  }
);

CheckoutButton.displayName = "CheckoutButton";

export default CheckoutButton;