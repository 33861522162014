import React, { useState } from "react";

import "../../assets/css/general_chat.css";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/Siri_like_animation.json";
import posthog from "posthog-js";
import { Movie } from "../../types/MovieType";

interface SignUpProps {
  inline?: boolean;
}


const GeneralChat: React.FC<SignUpProps> = () => {
  const [showAnimation, setShowAnimation] = useState(false);
  const navigate = useNavigate();

  const toggleAnimation = () => {
    posthog.capture("scenegpt_chat_selected");
    const character = {
      adult: false,
      gender: 0,
      id: 99999,
      known_for_department: "Suggestions",
      name: "Scenester",
      original_name: "Scenester",
      popularity: 10,
      profile_path: `${window.location.origin}/scenester.jpg`,
      cast_id: 123,
      character: "Scenester",
      credit_id: "abc123",
      from_movie: "",
      order: 1
    };

    navigate("/chat", {
      state: {
        movie: { title: character.from_movie, cast: [character] } as Movie,
        character: character,
        chat_type: "Scenester"
      }
    });
    setShowAnimation(!showAnimation);
  };

  return (
    <>
      <div className="animation-circle" onClick={toggleAnimation}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
        />
        {showAnimation && <div className="animation-dot"></div>}
      </div>
    </>
  );
};

export default GeneralChat;
