import React from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import failedAnimation from '../../assets/lotties/94303-failed.json';
import sceneGPTImage from '../../assets/lotties/sceneGPT.jpg';

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(165deg, #1c1c1f 0%, #141416 100%)',
  color: '#ffffff',
  padding: theme.spacing(3),
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
}));

const GlassCard = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '24px',
  padding: theme.spacing(4),
  maxWidth: '400px',
  width: '100%',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  position: 'relative',
  zIndex: 2,
}));

const RetryButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF9500 30%, #FF8A00 90%)',
  borderRadius: '50px',
  border: 0,
  color: 'white',
  padding: '12px 48px',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  marginTop: theme.spacing(3),
  textTransform: 'none',
  fontSize: '1.1rem',
  fontWeight: 600,
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8A00 30%, #FF7A00 90%)',
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 10px 2px rgba(255, 105, 135, .4)',
  },
}));

const Mascot = styled('img')({
  width: '80px',
  height: '80px',
  borderRadius: '50%',
  marginBottom: '20px',
  border: '2px solid #FF9500',
  padding: '2px',
});

const PaymentFailed: React.FC = () => {
  const navigate = useNavigate();

  const handleRetry = async () => {
    navigate('/profile');
  };

  return (
    <StyledContainer>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <GlassCard>
            <Mascot src={sceneGPTImage} alt="SceneGPT Mascot" />
            
            <Box sx={{ width: '120px', margin: '0 auto', marginBottom: 2 }}>
              <Lottie animationData={failedAnimation} loop={false} />
            </Box>

            <Typography variant="h5" component="h1" 
              sx={{ 
                fontWeight: 700, 
                marginBottom: 2,
                background: 'linear-gradient(45deg, #FF9500, #FF8A00)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}>
              Oops! A Small Hiccup
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: 3, opacity: 0.9 }}>
              Hey there! 👋 Thanks for trying to join our community. 
              The payment didn&apos;t go through, but don&apos;t worry - we&apos;ve saved your spot!
            </Typography>

            <RetryButton onClick={handleRetry} fullWidth>
              Let&apos;s Try Again 🚀
            </RetryButton>

            <Typography variant="caption" sx={{ display: 'block', marginTop: 2, opacity: 0.6 }}>
              Need help? We&apos;re here for you 24/7
            </Typography>
          </GlassCard>
        </motion.div>
      </AnimatePresence>
    </StyledContainer>
  );
};

export default PaymentFailed;