import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface ResetChatModalProps {
  isVisible: boolean;
  onContinue: () => void;
  onReset: () => void;
  setCheckoutModalOpen: (isOpen: boolean) => void;
}

const ResetChatModal: React.FC<ResetChatModalProps> = ({ isVisible, onContinue, onReset, setCheckoutModalOpen }) => (
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        style={{
          position: 'absolute',
          bottom: '70px',
          left: 0,
          right: 0,
          margin: '0 auto',
          padding: '14px 20px',
          background: 'rgba(28, 28, 30, 0.98)',
          backdropFilter: 'blur(25px)',
          WebkitBackdropFilter: 'blur(25px)',
          borderTop: '1px solid rgba(255, 255, 255, 0.05)',
          borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
          zIndex: 1000,
        }}
      >
        <div style={{
          maxWidth: '400px',
          margin: '0 auto',
        }}>
          <h2 style={{ 
            color: '#FF9500',
            fontSize: '15px',
            fontWeight: '600',
            margin: '0 0 4px',
            textAlign: 'center',
            letterSpacing: '-0.2px'
          }}>
            The chat is getting long.
          </h2>
          <p style={{ 
            color: 'rgba(235, 235, 245, 0.6)',
            fontSize: '13px',
            margin: '0 0 12px',
            textAlign: 'center',
            letterSpacing: '-0.1px'
          }}>
            Long chats cause you to reach your usage limits faster.
          </p>
          <div style={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
          }}>
            <button
              onClick={() => {
                onContinue();
                setCheckoutModalOpen(true);
              }}
              style={{
                background: 'rgba(255, 255, 255, 0.1)',
                border: 'none',
                borderRadius: '8px',
                padding: '12px',
                color: '#FF9500',
                fontSize: '15px',
                fontWeight: '500',
                cursor: 'pointer',
                transition: 'all 0.15s ease',
                WebkitTapHighlightColor: 'transparent',
                letterSpacing: '-0.2px'
              }}
            >
              Continue chat with MAX
            </button>
            <button
              onClick={onReset}
              style={{
                background: '#FF9500',
                border: 'none',
                borderRadius: '8px',
                padding: '12px',
                color: '#000',
                fontSize: '15px',
                fontWeight: '600',
                cursor: 'pointer',
                transition: 'all 0.15s ease',
                WebkitTapHighlightColor: 'transparent',
                letterSpacing: '-0.2px'
              }}
            >
              Start new chat
            </button>
          </div>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

export default ResetChatModal; 