import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faClock, faCheck, faGift } from '@fortawesome/free-solid-svg-icons';

interface LastChanceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  timeLeft: number;
  offerType: 'single-day' | 'yearly';
}

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const LastChanceModal: React.FC<LastChanceModalProps> = ({
  isOpen,
  onClose,
  onAccept,
  timeLeft,
  offerType,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && modalRef.current && contentRef.current) {
      gsap.fromTo(
        modalRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.3 }
      );
      gsap.fromTo(
        contentRef.current,
        { scale: 0.8, opacity: 0 },
        { scale: 1, opacity: 1, duration: 0.3, ease: 'back.out(1.2)' }
      );
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const renderTrialOffer = () => (
    <>
      <h2>
        Special Monday
        <br />
        24-Hour Access
      </h2>
      <p className="last-chance-subtitle">Try all premium features</p>
      
      <div className="last-chance-features">
        <div className="last-chance-feature-item">
          <FontAwesomeIcon icon={faCheck} />
          <span>Better Model</span>
        </div>
        <div className="last-chance-feature-item">
          <FontAwesomeIcon icon={faCheck} />
          <span>Mood Settings</span>
        </div>
        <div className="last-chance-feature-item">
          <FontAwesomeIcon icon={faCheck} />
          <span>Roleplay Features</span>
        </div>
      </div>

      <div className="last-chance-price-container">
        <span className="last-chance-original-price">$4.99</span>
        <div className="last-chance-discounted-price">
          $1.99
          <span className="last-chance-savings-badge">Save 60%</span>
        </div>
        <span className="last-chance-price-period">24-hour access</span>
      </div>
    </>
  );

  const renderYearlyOffer = () => (
    <>
      <h2>
        Exclusive Yearly Offer
      </h2>
      <p className="last-chance-subtitle">Our best value package</p>
      
      <div className="last-chance-features">
        <div className="last-chance-feature-item">
          <FontAwesomeIcon icon={faCheck} />
          <span>Ad free</span>
        </div>
        <div className="last-chance-feature-item">
          <FontAwesomeIcon icon={faCheck} />
          <span>Better Model</span>
        </div>
        <div className="last-chance-feature-item">
          <FontAwesomeIcon icon={faCheck} />
          <span>Mood Settings</span>
        </div>
      </div>

      <div className="last-chance-price-container">
        <span className="last-chance-original-price">$114.99/year</span>
        <div className="last-chance-discounted-price">
          $59.99/year
          <span className="last-chance-savings-badge">Save 48%</span>
        </div>
        <span className="last-chance-price-period">Just $1.15/week</span>
      </div>
    </>
  );

  return (
    <div
      className="modal-overlay"
      onClick={onClose}
      style={{ zIndex: 1000001 }} // Ensure it's above the main modal
    >
      <div 
        ref={modalRef}
        className="last-chance-modal-container"
        onClick={e => e.stopPropagation()}
      >
        <button className="last-chance-close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <div className="last-chance-content">
          <div className="last-chance-icon">
            <FontAwesomeIcon icon={faGift} />
          </div>

          <div className="last-chance-time-badge">
            <FontAwesomeIcon icon={faClock} />
            <span>{formatTime(timeLeft)}</span>
          </div>

          <div className="last-chance-offer">
            {offerType === 'single-day' ? renderTrialOffer() : renderYearlyOffer()}
          </div>

          <button className="last-chance-accept-button" onClick={onAccept}>
            Get Started Now
          </button>

          <p className="last-chance-secure-text">
            🔒 Secure payment • Cancel anytime
          </p>
        </div>
      </div>
    </div>
  );
};

export default LastChanceModal;
