// ShareContent.tsx

import React from "react";
import { Box, IconButton, CircularProgress } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import ShareIcon from "@mui/icons-material/Share";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import useIsMobile from "../hooks/useIsMobile";

interface ExportData {
  character: string;
}

interface ShareData {
  title: string;
  text: string;
  url: string;
  files?: File[];
}

interface ShareContentProps {
  copyLinkToClipboard: () => void;
  modalCommand: () => void;
  isSaving: boolean;
  exportData?: ExportData;
  saveAndGetShareUrl: () => Promise<string>;
  generateShareImage: () => Promise<string | null>;
  shareType: "secret" | "public";
}

const ShareContent: React.FC<ShareContentProps> = ({
                                                     copyLinkToClipboard,
                                                     isSaving,
                                                     exportData,
                                                     saveAndGetShareUrl,
                                                     generateShareImage,
                                                     modalCommand
                                                   }) => {
  const shareContent = async () => {
    try {
      const url = await saveAndGetShareUrl();
      const text = `Check out my chat with ${exportData?.character}!`;
      const shareData: ShareData = {
        title: "sceneXtras Inc.",
        text: text,
        url: url
      };

      const imageDataUrl = await generateShareImage();
      if (imageDataUrl) {
        const response = await fetch(imageDataUrl);
        const blob = await response.blob();
        const file = new File(
          [blob],
          `chat_with_${exportData?.character.replace(/\s+/g, "_")}.png`,
          { type: blob.type }
        );

        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          shareData.files = [file];
        } else {
          // Fallback: Download the image and inform the user to attach it manually
          const link = document.createElement("a");
          link.href = imageDataUrl;
          link.download = `chat_with_${exportData?.character.replace(/\s+/g, "_")}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          toast.info("Image downloaded. Please attach it manually when sharing.");
        }
      }


      if (navigator.share) {
        await navigator.share(shareData);
        // console.log("Content shared successfully");
      } else {
        // Fallback: Inform the user to copy the link manually
        toast.info("Sharing is not supported on this browser. Please copy the link manually.");
      }
      modalCommand();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Failed to share:", error);
      toast.error("Failed to share");
    }
  };

  return (
    <IconButton onClick={shareContent} color="primary" aria-label="Share">
      <ShareIcon />
    </IconButton>
  );
};


export default ShareContent;
