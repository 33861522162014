import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { gsap } from 'gsap';
import sceneGptNoBg from '../../assets/img/scenegpt_no_bg.png';

export function Mascot() {
  const mascotRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mascotRef.current) return;

    // Initial animation
    gsap.fromTo(
      mascotRef.current,
      {
        y: 20,
        opacity: 0,
        scale: 0.9
      },
      {
        y: 0,
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: 'back.out(1.7)'
      }
    );

    // Floating animation
    gsap.to(mascotRef.current, {
      y: '+=10',
      duration: 2,
      yoyo: true,
      repeat: -1,
      ease: 'sine.inOut'
    });

    return () => {
      gsap.killTweensOf(mascotRef.current);
    };
  }, []);

  return (
    <Box
      ref={mascotRef}
      sx={{
        width: 120,
        height: 120,
        mb: 4,
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: -10,
          left: '50%',
          transform: 'translateX(-50%)',
          width: 80,
          height: 20,
          background: 'radial-gradient(ellipse at center, rgba(255,149,0,0.2) 0%, rgba(255,149,0,0) 70%)',
          borderRadius: '50%'
        }
      }}
    >
      <img
        src={sceneGptNoBg}
        alt="SceneGPT Mascot"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.2))'
        }}
      />
    </Box>
  );
}
