import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { motion } from "framer-motion";
import logo from "../../assets/img/scenextras-full-logo-1@2x.png";

const StarField = () => {
  // Create a symmetric grid of stars
  const gridSize = 10; // 10x10 grid
  const stars = Array.from({ length: gridSize * gridSize }, (_, index) => {
    const row = Math.floor(index / gridSize);
    const col = index % gridSize;
    
    return {
      x: `${(col / (gridSize - 1)) * 100}%`,
      y: `${(row / (gridSize - 1)) * 70}%`,
      size: Math.random() * 2 + 1,
      delay: (row + col) * 0.04,
      isSpecial: (row + col) % 5 === 0
    };
  });

  return (
    <>
      {stars.map((star, i) => (
        <motion.div
          key={i}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ 
            scale: 1, 
            opacity: star.isSpecial ? 0.6 : 0.4
          }}
          transition={{
            delay: star.delay,
            duration: 0.3,
            repeat: Infinity,
            repeatType: "reverse",
            repeatDelay: star.isSpecial ? 2 : 1.5
          }}
          style={{
            position: "fixed",
            left: star.x,
            top: star.y,
            width: star.size,
            height: star.size,
            borderRadius: '50%',
            background: star.isSpecial ? "#FF8A00" : "#ffffff",
            boxShadow: star.isSpecial ? "0 0 4px #ffffff" : "none",
            filter: star.isSpecial ? 'blur(0.7px)' : 'none',
            zIndex: 0,
            willChange: 'transform, opacity'
          }}
        />
      ))}
    </>
  );
};

const LoadingScreen = () => {
  const logoRef = useRef(null);
  const screenRef = useRef(null);

  useEffect(() => {
    const heartbeat = gsap.timeline({ repeat: -1, repeatDelay: 0.6 })
      .to(logoRef.current, {
        scale: 1.1,
        duration: 0.1,
        ease: "power1.inOut"
      })
      .to(logoRef.current, {
        scale: 1,
        duration: 0.1,
        ease: "power1.inOut"
      });

    gsap.set(logoRef.current, { opacity: 1 });

    const fadeOut = () => {
      gsap.to(screenRef.current, {
        opacity: 0,
        duration: 0.5,
        ease: "power2.inOut",
        onComplete: () => {
          if (screenRef.current) {
            screenRef.current.style.display = "none";
          }
        }
      });
    };

    const timer = setTimeout(() => fadeOut(), 5000);

    return () => {
      clearTimeout(timer);
      heartbeat.kill();
    };
  }, []);

  return (
    <div ref={screenRef} className="loading-screen"
         style={{ 
           display: "flex", 
           justifyContent: "center", 
           alignItems: "center", 
           height: "100vh", 
           opacity: 1,
           backgroundColor: '#121214',
           position: 'relative',
           overflow: 'hidden'
         }}>
      <StarField />
      <img ref={logoRef} className="logo" src={logo} alt="Full Logo" 
           style={{ width: "200px", height: "auto", position: 'relative', zIndex: 1 }} />
    </div>
  );
};

export default LoadingScreen;
