import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { gsap } from 'gsap';

interface AnimatedTextProps {
  text: string;
}

export function AnimatedText({ text }: AnimatedTextProps) {
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!textRef.current) return;

    // Clear previous content
    const words = text.split(' ');
    textRef.current.innerHTML = '';
    
    // Create spans for each word
    words.forEach((word, index) => {
      const wordSpan = document.createElement('span');
      wordSpan.style.display = 'inline-block';
      wordSpan.style.opacity = '0';
      wordSpan.style.transform = 'translateY(10px)';
      wordSpan.style.marginRight = index < words.length - 1 ? '0.25em' : '0';
      
      // Split word into characters
      const chars = word.split('');
      chars.forEach(char => {
        const charSpan = document.createElement('span');
        charSpan.textContent = char;
        charSpan.style.display = 'inline-block';
        wordSpan.appendChild(charSpan);
      });
      
      textRef.current?.appendChild(wordSpan);
    });

    // Animate each word
    gsap.to(textRef.current.children, {
      opacity: 1,
      y: 0,
      duration: 0.4,
      stagger: 0.04,
      ease: 'power2.out'
    });
  }, [text]);

  return (
    <Box
      ref={textRef}
      sx={{
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 600,
        fontSize: { xs: '22px', sm: '24px' },
        letterSpacing: '-0.02em',
        textAlign: 'center',
        mb: 3,
        color: '#ffffff',
        minHeight: '2em',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '280px',
        mx: 'auto',
        px: 2,
        lineHeight: 1.3,
        '& > span': {
          display: 'inline-flex',
          alignItems: 'center',
          minHeight: '1.3em',
        }
      }}
    >
      {text}
    </Box>
  );
}
