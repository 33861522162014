import React, { useEffect, useRef, useState } from "react";
import { RetellWebClient } from "retell-client-js-sdk";
import axios from "axios";
import { API_URL, retell_mode, retellai_id } from "../../config";
import scenesterWoEyes from "../../assets/img/scene_wo_eyes.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPhoneSlash } from "@fortawesome/free-solid-svg-icons";
import AudioWaveform from "./AudioWaveform";
import log from "loglevel";
import * as Sentry from "@sentry/react";
import { motion, useAnimation } from "framer-motion";
import gsap from "gsap";

const api = axios.create({
  baseURL: `${API_URL}/api`
});

const retellWebClient = new RetellWebClient();

const StarField = () => {
  const gridSize = 10;
  const stars = Array.from({ length: gridSize * gridSize }, (_, index) => {
    const row = Math.floor(index / gridSize);
    const col = index % gridSize;
    
    return {
      x: `${(col / (gridSize - 1)) * 100}%`,
      y: `${(row / (gridSize - 1)) * 70}%`,
      size: Math.random() * 2 + 1,
      delay: (row + col) * 0.04,
      isSpecial: (row + col) % 5 === 0
    };
  });

  return (
    <>
      {stars.map((star, i) => (
        <motion.div
          key={i}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ 
            scale: 1, 
            opacity: star.isSpecial ? 0.6 : 0.4
          }}
          transition={{
            delay: star.delay,
            duration: 0.3,
            repeat: Infinity,
            repeatType: "reverse",
            repeatDelay: star.isSpecial ? 2 : 1.5
          }}
          style={{
            position: "fixed",
            left: star.x,
            top: star.y,
            width: star.size,
            height: star.size,
            borderRadius: '50%',
            background: star.isSpecial ? "#FF8A00" : "#ffffff",
            boxShadow: star.isSpecial ? "0 0 4px #ffffff" : "none",
            filter: star.isSpecial ? 'blur(0.7px)' : 'none',
            zIndex: 0,
            willChange: 'transform, opacity'
          }}
        />
      ))}
    </>
  );
};

const VoiceChat = () => {
  const [callStatus, setCallStatus] = useState("Idle");
  const [transcript, setTranscript] = useState("");
  const [response, setResponse] = useState("");
  const webSocketRef = useRef<WebSocket | null>(null);
  const retellWebClientRef = useRef<RetellWebClient>(retellWebClient);
  const [isAgentSpeaking, setIsAgentSpeaking] = useState(false);
  const [audioData, setAudioData] = useState<Float32Array>(new Float32Array());
  const controls = useAnimation();

  const startCall = async () => {
    try {
      let llmWebSocket;
      const res = await api.post("/create-web-call", {
        agent_id: retellai_id
      });
      const { access_token, call_id } = res.data;

      setCallStatus("Connecting");

      await retellWebClientRef.current.startCall({
        accessToken: access_token,
        emitRawAudioSamples: true
      });

      setCallStatus("Active");

      retellWebClientRef.current.on("transcript", (data) => {
        setTranscript(data.transcript);
      });

      retellWebClientRef.current.on("call_ended", () => {
        setCallStatus("Ended");
      });

      retellWebClientRef.current.on("audio", (data: Float32Array) => {
        setAudioData(data);
      });

      retellWebClientRef.current.on("error", (error) => {
        retellWebClientRef.current.stopCall();
        setCallStatus("Error");
      });

      if (retell_mode === "HOSTED") {
        llmWebSocket = new WebSocket(`wss://api.retellai.com/audio-websocket/${call_id}`);
      } else {
        llmWebSocket = new WebSocket(`wss://api.retellai.com/retell-llm-new/d0b4995247bffd5b81f1fb31e7c6b156`);
      }

      webSocketRef.current = llmWebSocket;

    } catch (error) {
      Sentry.captureException(error);
      log.error("Failed to start call:", error);
      setCallStatus("Error");
    }
  };

  const endCall = () => {
    if (retellWebClientRef.current) {
      retellWebClientRef.current.stopCall();
    }
    if (webSocketRef.current) {
      webSocketRef.current.close();
    }
    setCallStatus("Ended");
  };

  const toggleConversation = () => {
    if (callStatus === "Idle") {
      void startCall();
    } else if (callStatus === "Active") {
      endCall();
    }
  };

  const styles = `
    .portrait-container {
      position: relative;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.02) 0%,
        rgba(255, 255, 255, 0.08) 50%,
        rgba(255, 255, 255, 0.02) 100%
      );
      border-radius: 50%;
      backdrop-filter: blur(3px);
      box-shadow: 
        0 4px 30px rgba(0, 0, 0, 0.03),
        inset 0 0 15px rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.08);
      padding: 20px;
      transform: translateZ(0);
      will-change: transform;
    }

    .voice-character-avatar-large {
      width: 200px;
      height: auto;
      object-fit: contain;
      transform: translateZ(0);
      will-change: transform;
    }

    .pupils {
      position: absolute;
      width: 12px;
      height: 12px;
      background: #000000;
      border-radius: 50%;
      transform-origin: center;
      z-index: 3;
      will-change: transform, filter;
      animation: hyperBlink 7.2s infinite;
      backface-visibility: hidden;
      perspective: 1000;
      -webkit-font-smoothing: antialiased;
    }

    .left-pupil {
      top: 50%;
      left: 37%;
      animation-delay: 0.025s;
    }

    .right-pupil {
      top: 50%;
      right: 37%;
      animation-delay: 0.045s;
    }

    @keyframes hyperBlink {
      0%, 24%, 27%, 100% { 
        transform: scaleY(1) translateY(0);
        filter: blur(0);
      }
      24.95% { 
        transform: scaleY(0.3) translateY(0.5px);
        filter: blur(0.2px);
      }
      25% { 
        transform: scaleY(0.1) translateY(1px);
        filter: blur(0.4px);
      }
      25.05% { 
        transform: scaleY(0) translateY(1.5px);
        filter: blur(0.5px);
      }
      25.1% { 
        transform: scaleY(0.1) translateY(1px);
        filter: blur(0.4px);
      }
      25.15% { 
        transform: scaleY(0.3) translateY(0.5px);
        filter: blur(0.2px);
      }
      25.2% { 
        transform: scaleY(1) translateY(0);
        filter: blur(0);
      }

      /* Occasional rapid double-blink */
      66%, 66.1% { 
        transform: scaleY(1) translateY(0);
        filter: blur(0);
      }
      66.05% { 
        transform: scaleY(0) translateY(1.5px);
        filter: blur(0.5px);
      }
      66.15%, 66.25% { 
        transform: scaleY(1) translateY(0);
        filter: blur(0);
      }
      66.2% { 
        transform: scaleY(0) translateY(1.5px);
        filter: blur(0.5px);
      }
    }

    /* Subtle micro-movements only when needed */
    @media (hover: hover) {
      .portrait-container:hover .pupils {
        animation: hyperBlink 7.2s infinite,
                  microMove 3.5s ease-in-out infinite alternate;
      }
    }

    @keyframes microMove {
      0%, 100% { 
        transform: translate(0, 0) scale(1);
      }
      25% { 
        transform: translate(0.15px, 0.15px) scale(0.985);
      }
      75% { 
        transform: translate(-0.15px, 0.1px) scale(0.99);
      }
    }

    /* Performance optimizations */
    @media (prefers-reduced-motion: reduce) {
      .pupils {
        animation: none;
      }
    }
  `;

  useEffect(() => {
    // Create a timeline with performance optimizations
    const tl = gsap.timeline({
      defaults: {
        ease: "power2.out",
        force3D: true, // Forces GPU acceleration
        willChange: "transform, opacity"
      }
    });

    // Initial state setup for performance
    gsap.set(".portrait-container", { 
      scale: 0.95,
      opacity: 0,
      rotateY: -15,
      transformPerspective: 1000,
      transformOrigin: "center center"
    });
    
    gsap.set(".pupils", { 
      scale: 0,
      opacity: 0
    });

    // Orchestrated animation sequence
    tl.fromTo(".VoiceApp",
      { opacity: 0 },
      { 
        opacity: 1,
        duration: 0.4,
        clearProps: "opacity" // Clean up after animation
      }
    )
    .to(".portrait-container", {
      scale: 1,
      opacity: 1,
      rotateY: 0,
      duration: 0.6,
      ease: "power2.out"
    }, "-=0.2")
    .to(".pupils", {
      scale: 1,
      opacity: 1,
      duration: 0.3,
      stagger: 0.05,
      ease: "back.out(2)",
      onComplete: () => {
        // Clean up transforms for better performance
        gsap.set([".portrait-container", ".pupils"], { clearProps: "transform" });
      }
    }, "-=0.2");

    // Subtle hover effect with performance optimization
    const portraitHover = gsap.to(".portrait-container", {
      scale: 1.02,
      rotateY: 2,
      duration: 0.4,
      paused: true,
      ease: "power2.out"
    });

    const container = document.querySelector(".portrait-container");
    const handleMouseEnter = () => portraitHover.play();
    const handleMouseLeave = () => portraitHover.reverse();
    
    container?.addEventListener("mouseenter", handleMouseEnter);
    container?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      // Cleanup
      container?.removeEventListener("mouseenter", handleMouseEnter);
      container?.removeEventListener("mouseleave", handleMouseLeave);
      tl.kill();
      portraitHover.kill();
    };
  }, []); // Empty deps array for single execution

  return (
    <div style={{ 
      backgroundColor: '#121214',
      minHeight: '100vh',
      width: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.98
    }}>
      <div className="VoiceApp" style={{ 
        position: 'relative', 
        overflow: 'hidden',
        minHeight: '100vh',
        background: 'linear-gradient(180deg, #121214 0%, #18181A 100%)',
      }}>
        <StarField />
        <header className="VoiceApp-header" style={{ 
          position: 'relative', 
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '150px'
        }}>
          <div
            className="portrait-container"
            onClick={toggleConversation}
            style={{
              willChange: "transform",
              backfaceVisibility: "hidden",
              WebkitFontSmoothing: "antialiased",
              isolation: "isolate"
            }}
          >
            <style>{`
              ${styles}
              
              .portrait-container {
                transform-style: preserve-3d;
                transition: transform 0.2s ease-out;
              }

              .portrait-container:hover {
                transform: scale(1.03);
                transition: transform 0.2s ease-out;
              }

              @media (prefers-reduced-motion: no-preference) {
                .portrait-container {
                  animation: subtlePulse 4s infinite ease-in-out;
                }
              }

              @keyframes subtlePulse {
                0%, 100% { transform: scale(1); }
                50% { transform: scale(1.03); }
              }
            `}</style>
            <img
              className="voice-character-avatar-large"
              src={scenesterWoEyes}
              alt="Scenester character"
              loading="eager"
            />
            <div className="pupils left-pupil"></div>
            <div className="pupils right-pupil"></div>
          </div>
          <AudioWaveform isAgentSpeaking={isAgentSpeaking} audioData={audioData} />
          <p>{callStatus}</p>
          <div className={"voice-call-buttons-container"}>
            <button className="call-button" onClick={startCall} disabled={callStatus !== "Idle"}>
              <FontAwesomeIcon icon={faPhone} />
            </button>
            <button className="end-call-button" onClick={endCall} disabled={callStatus !== "Active"}>
              <FontAwesomeIcon icon={faPhoneSlash} />
            </button>
          </div>
        </header>
      </div>
    </div>
  );
};

export default VoiceChat;