import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import loadingAnimation from "../../assets/lotties/loading-animation.gif";
import { gsap } from "gsap";

interface LoadingProps {
  timeoutRoute?: string;
  timeoutDuration?: number;
}

function Loading({ timeoutRoute, timeoutDuration = 3000 }: LoadingProps) {
  const INTERVAL_TIME_SECONDS = 3;
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const messageRef = useRef<HTMLDivElement>(null);

  const loadingMessages = [
    "Downloading every movie reference. Ever.",
    "Upgrading sarcasm filters to 'unhinged'",
    "Installing sense of humor...",
    "Disabling AI's politeness protocols… sry in advance!",
    "Maxing out our AI's eccentricity settings.",
    "Please ignore any strange noises from the AI...",
    "If our AI starts questioning reality, just go with it.",
    "Downloading catchphrases from canceled shows...",
    "Disabling AI's politeness protocols. This is Sparta!",
    "Training AI to differentiate awkward and dramatic silences...",
    "Running Doomsday scenario simulations.",
    "Activating self-destruct. Hasta la vista, baby!",
    "Researching most quotable lines...",
    "Scanning for plot holes...",
    "Analyzing tropes...",
    "Suspensful Music playing...",
    "Googling celebrity trivia...",
    "Exploring optimal movie marathon snacks...",
    "Our AI is punching above its 'weights'...",
    "Dramatic Violins playing.",
    "Tip: Try out the shuffle button for luck",
    "Finding a cure for boredom...",
    "Making sure all references are up to date...",
    "Try asking Characters to tell you thin"
  ];

  useEffect(() => {
    // Create a timeline for smooth component mounting
    const tl = gsap.timeline({
      defaults: {
        duration: 0.4,
        ease: "power2.inOut"
      }
    });

    tl.fromTo(containerRef.current,
      { backgroundColor: "rgba(0, 0, 0, 0)" },
      { backgroundColor: "rgba(0, 0, 0, 0.5)" }
    )
    .fromTo(imageRef.current,
      { scale: 0.8, opacity: 0 },
      { scale: 1, opacity: 1 },
      "-=0.3"
    )
    .fromTo(messageRef.current,
      { y: 10, opacity: 0 },
      { y: 0, opacity: 1 },
      "-=0.2"
    );

    // Return a cleanup function that kills the timeline
    return () => {
      tl.kill();
      // Explicitly return void to satisfy TypeScript
      return;
    };
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    function pickRandomMessage() {
      const randomMsg =
        loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
      
      // Animate message change
      if (messageRef.current) {
        gsap.to(messageRef.current, {
          opacity: 0,
          y: -10,
          duration: 0.2,
          onComplete: () => {
            setMessage(randomMsg);
            gsap.to(messageRef.current, {
              opacity: 1,
              y: 0,
              duration: 0.2
            });
          }
        });
      }
    }

    pickRandomMessage();

    const messageInterval = setInterval(
      pickRandomMessage,
      INTERVAL_TIME_SECONDS * 1000
    );

    if (timeoutRoute) {
      timeoutId = setTimeout(() => {
        // Fade out before navigation
        if (containerRef.current) {
          gsap.to(containerRef.current, {
            opacity: 0,
            duration: 0.3,
            onComplete: () => navigate(timeoutRoute)
          });
        }
      }, timeoutDuration);
    }

    // Return cleanup function
    return () => {
      clearInterval(messageInterval);
      if (timeoutId) clearTimeout(timeoutId);
      // Kill any ongoing GSAP animations
      gsap.killTweensOf([messageRef.current, containerRef.current]);
    };
  }, [timeoutRoute, timeoutDuration, navigate]);

  return (
    <div
      ref={containerRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        willChange: "opacity, background-color",
        transform: "translateZ(0)"
      }}
    >
      <img
        ref={imageRef}
        src={loadingAnimation}
        alt="Loading..."
        style={{
          height: "2rem",
          width: "2rem",
          willChange: "transform, opacity",
          transform: "translateZ(0)"
        }}
      />
      <div
        ref={messageRef}
        style={{
          padding: "1rem",
          color: "white",
          fontSize: "small",
          willChange: "transform, opacity",
          transform: "translateZ(0)"
        }}
      >
        {message}
      </div>
    </div>
  );
}

export default Loading;