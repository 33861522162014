import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getPopularContent,
  searchActorsFilmography,
  searchAnimesWithCast,
  searchCartoonsWithCast,
  searchMoviesWithCast,
  searchSeriesWithCast
} from "../../api/apiClient";
import MovieDisplay from "./MovieDisplay";
import { Movie } from "../../types/MovieType";
import { Cast } from "../../types/CastType";
import CharacterCarousel from "../miscs/CharacterCarousel";
import "../../css/searchList.scss";
import { getWithExpiry, getWithoutExpiry, setWithExpiry } from "../../helper/storageUtils";
import GeneralChat from "../chat/GeneralChat";
import Loading from "../miscs/Loading";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import Seo from "../../helper/Seo";
import log from "loglevel";
import gsap from "gsap";
import { getPopularKey, getSearchResultsKey, POPULAR_MOVIE_CAST_LIMIT, POPULAR_MOVIE_LIMIT } from "../../config";
import CameraCaptureSearch from "../miscs/CameraCaptureSearch";
import "../../../src/assets/css/upgradebtn.css";
import { AuthenticatedUser } from "../../helper/authHelper";
import { ModalContext } from "../modals/ModalContext";

type ContentType = "movies" | "series" | "animes" | "cartoons" | "actors";
type TabName = Capitalize<ContentType>;

type ContentResults = {
  [K in ContentType]: Movie[];
};

const CACHE_EXPIRY = 24 * 60 * 60 * 5000; // 5 days in milliseconds
const ITEMS_PER_PAGE = 6;

const MAX_CACHED_SEARCHES = 10;

function getCachedSearches(category: ContentType): { [query: string]: Movie[] } {
  return JSON.parse(getWithExpiry(`cachedSearches_new_${category}`) || "{}");
}

function setCachedSearches(category: ContentType, searches: { [query: string]: Movie[] }) {
  setWithExpiry(`cachedSearches_${category}`, JSON.stringify(searches), CACHE_EXPIRY);
}

function addCachedSearch(category: ContentType, query: string, results: Movie[]) {
  const cachedSearches = getCachedSearches(category);
  cachedSearches[query] = results;

  const queries = Object.keys(cachedSearches);
  if (queries.length > MAX_CACHED_SEARCHES) {
    delete cachedSearches[queries[0]];
  }

  setCachedSearches(category, cachedSearches);
}

interface SearchProps {
  user: AuthenticatedUser;
}

const Search: React.FC<SearchProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [isResourceLoading, setResourceIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [popularContent, setPopularContent] = useState<ContentResults>({
    movies: [],
    series: [],
    animes: [],
    cartoons: [],
    actors: []
  });
  const [searchResults, setSearchResults] = useState<ContentResults>({
    movies: [],
    series: [],
    animes: [],
    cartoons: [],
    actors: []
  });
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const [activeTab, setActiveTab] = useState<TabName>("Movies");
  const [tabOffset, setTabOffset] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);
  const moviesRef = useRef<HTMLDivElement>(null);
  const [isMacBook, setIsMacBook] = useState(false);
  const [searchMade, setSearchMade] = useState(false);
  const [characterCarousel, setCharacterCarousel] = useState<Cast[]>();
  const searchInputRef = useRef<HTMLInputElement>(null);

  let start = 0;
  let startScroll = 0;
  let isDragging = false;
  const isVertical = true;

  const normalizeSearchTerm = (term: string): string => {
    return term.trim().toLowerCase();
  };

  const fetchPopularContent = useCallback(async (contentType: ContentType) => {
    setResourceIsLoading(true);
    try {
      const response = await getPopularContent(contentType, POPULAR_MOVIE_LIMIT, POPULAR_MOVIE_CAST_LIMIT);

      if (!response || response.length === 0) {
        throw new Error(`No ${contentType} data received from server`);
      }

      setPopularContent(prevContent => ({
        ...prevContent,
        [contentType]: response
      }));
      setWithExpiry(getPopularKey(contentType), JSON.stringify(response), CACHE_EXPIRY);
    } catch (error) {
      Sentry.captureException(error);
      log.error(error);
      log.error(`Error fetching popular ${contentType}:`, error);
      toast.error(`Error fetching popular ${contentType}.`);
    } finally {
      setResourceIsLoading(false);
    }
  }, []);


  const loadPopularContent = useCallback((contentType: ContentType) => {
    const cachedContent = getWithExpiry(getPopularKey(contentType));
    if (cachedContent) {
      try {
        const parsedContent = JSON.parse(cachedContent);

        // Check if parsedContent is empty
        if (!parsedContent || parsedContent.length === 0) {
          log.info(`Cached ${contentType} content is empty, fetching new data`);
          fetchPopularContent(contentType);
        } else {
          setPopularContent(prevContent => ({
            ...prevContent,
            [contentType]: parsedContent
          }));
          log.info(`Loaded cached ${contentType} content`);
        }

      } catch (error) {
        Sentry.captureException(error);
        log.error(`Error parsing cached ${contentType} content:`, error);
        fetchPopularContent(contentType);
      }
    } else {
      log.info(`No cached ${contentType} content, fetching new data`);
      fetchPopularContent(contentType);
    }
  }, [fetchPopularContent]);

  useEffect(() => {
    const contentTypes: ContentType[] = ["movies", "series", "animes", "actors", "cartoons"];
    contentTypes.forEach(loadPopularContent);
  }, [loadPopularContent]);

  const toTabName = (contentType: ContentType): TabName => {
    return contentType.charAt(0).toUpperCase() + contentType.slice(1) as TabName;
  };

  useEffect(() => {
    const getTopCharactersPerItem = (items: Movie[], countPerItem: number): Cast[] => {
      return items.flatMap(item =>
        (item.cast || [])
          .filter(character =>
            character.name &&
            character.profile_path &&
            character.character
          )
          .sort((a, b) => (b.popularity || 0) - (a.popularity || 0))
          .slice(0, countPerItem)
          .map(character => ({
            ...character,
            from_movie: item.title || item.original_title || item.name || ""
          }))
      );
    };

    const shuffleArray = (array: any[]) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const hashContent = (content: ContentResults): string => {
      const contentString = JSON.stringify(content);
      let hash = 0;
      for (let i = 0; i < contentString.length; i++) {
        const char = contentString.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
      }
      return hash.toString();
    };

    const currentHash = hashContent(popularContent);
    const cachedCarousel = localStorage.getItem("characterCarousel");
    const cachedHash = localStorage.getItem("characterCarouselHash");

    if (cachedCarousel && cachedHash && cachedHash === currentHash) {
      setCharacterCarousel(JSON.parse(cachedCarousel));
    } else {
      const topMovieCharacters = getTopCharactersPerItem(popularContent.movies, 10);
      const topSeriesCharacters = getTopCharactersPerItem(popularContent.series, 10);
      const topAnimeCharacters = getTopCharactersPerItem(popularContent.animes, 10);
      const topCartoonCharacters = getTopCharactersPerItem(popularContent.cartoons, 10);

      const allTopCharacters = [
        ...topMovieCharacters,
        ...topSeriesCharacters,
        ...topAnimeCharacters,
        ...topCartoonCharacters
      ];

      const messagesJson = getWithoutExpiry("messages");
      const messageCharacters: Cast[] = [];
      if (messagesJson) {
        try {
          const parsedData = JSON.parse(messagesJson); // parsedData is an object
          for (const characterName in parsedData) {
            const messagesArray = parsedData[characterName];
            // Extract profileImage from the first message
            const profileImage = messagesArray[0]?.profileImage || null;
            if (!profileImage) {
              continue;
            }
            messageCharacters.push({
              name: characterName,
              profile_path: profileImage
                ? profileImage
                : `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`,
              character: characterName,
              from_movie: "", // If you have movie info, you can include it here
              isFromMessages: true
            });
          }
        } catch (error) {
          Sentry.captureException(error);
          console.error("Error parsing messages JSON:", error);
        }
      }

      log.info("messageCharacters:", messageCharacters);
      const shuffledCharacters = shuffleArray(allTopCharacters);

      const allCharacters = [
        ...shuffledCharacters,
        ...messageCharacters
      ];

      const isPlaceholderOrQuestionmark = (imagePath: string): boolean => {
        return imagePath.includes("Placeholder-Portrait.jpg") ||
          imagePath.includes("questionmark") ||
          imagePath === `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`;
      };

      const uniqueCharacters = allCharacters.reduce((acc: Cast[], current) => {
        const isDuplicate = acc.find(item => item.name === current.name);
        if (!isDuplicate && (!isPlaceholderOrQuestionmark(current.profile_path) || current.isFromMessages)) {
          acc.push(current);
        }
        return acc;
      }, []);

      const combinedShuffledCharacters = shuffleArray(uniqueCharacters);

      setCharacterCarousel(combinedShuffledCharacters);
      localStorage.setItem("characterCarousel", JSON.stringify(combinedShuffledCharacters));
      localStorage.setItem("characterCarouselHash", currentHash);
    }
  }, [popularContent]);

  useEffect(() => {
    const storedSearchTerm = sessionStorage.getItem("searchTerm");
    const storedActiveTab = sessionStorage.getItem("activeTab") as TabName | null;
    const storedSearchResults = sessionStorage.getItem(getSearchResultsKey());

    if (storedSearchTerm && storedActiveTab) {
      setSearchTerm(storedSearchTerm); // Keep the original casing for display
      setActiveTab(storedActiveTab);
      if (storedSearchResults) {
        setSearchResults(JSON.parse(storedSearchResults));
        setSearchMade(true);
      } else {
        fetchSearchResults(storedSearchTerm, storedActiveTab);
      }
    }
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];

    if (macosPlatforms.indexOf(platform) !== -1 && userAgent.indexOf("Chrome") !== -1) {
      setIsMacBook(true);
    }
  }, []);

  useEffect(() => {
    let currentIndex = 0;
    const searchInput = document.querySelector(".search-input");

    const typePlaceholder = () => {
      if (searchInput) {
        const movie = topMovies[currentIndex];
        let charIndex = 0;
        const typeChar = () => {
          if (charIndex < movie.length) {
            searchInput.setAttribute(
              "placeholder",
              movie.slice(0, charIndex + 1)
            );
            charIndex++;
            setTimeout(typeChar, 100);
          } else {
            currentIndex = (currentIndex + 1) % topMovies.length;
            setTimeout(typePlaceholder, 5000);
          }
        };
        typeChar();
      }
    };

    typePlaceholder();

    const lightsweep = () => {
      gsap.fromTo(
        ".search-input",
        { backgroundPosition: "200% 0" },
        {
          backgroundPosition: "-200% 0",
          duration: 2,
          ease: "none",
          repeat: -1
        }
      );
    };

    lightsweep();

    return () => {
      gsap.killTweensOf(".search-input");
    };
  }, []);

  const onDragStart = (e: React.MouseEvent) => {
    if (e.button !== 0) return; // Ensure left click only
    start = isVertical ? e.clientY : e.clientX;
    if (containerRef.current) {
      startScroll = isVertical
        ? containerRef.current.scrollTop
        : containerRef.current.scrollLeft;
    }
    isDragging = true;
    document.addEventListener("mousemove", onDragging);
    document.addEventListener("mouseup", onDragEnd);
    e.preventDefault(); // Prevent text selection or other default actions
  };

  const onDragging = (e: MouseEvent) => {
    if (!isDragging || !containerRef.current) return;
    const diff = isVertical ? e.clientY - start : e.clientX - start;
    if (isVertical) {
      containerRef.current.scrollTop = startScroll - diff;
    } else {
      containerRef.current.scrollLeft = startScroll - diff;
    }
  };

  const onDragEnd = () => {
    isDragging = false;
    document.removeEventListener("mousemove", onDragging);
    document.removeEventListener("mouseup", onDragEnd);
  };


  const topMovies = [
    "Avatar",
    "Avengers: Endgame",
    "Titanic",
    "Star Wars: The Force Awakens",
    "Avengers: Infinity War",
    "Jurassic World",
    "The Lion King",
    "The Avengers",
    "Furious 7",
    "Frozen II"
  ];

  const clearSearch = () => {
    setSearchTerm("");
    setSearchResults({ movies: [], series: [], animes: [], cartoons: [], actors: [] });
    setSearchMade(false);
    sessionStorage.removeItem("searchTerm");
    sessionStorage.removeItem("activeTab");
    sessionStorage.removeItem("searchResults");
    sessionStorage.removeItem(getSearchResultsKey());
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const normalizedValue = normalizeSearchTerm(value);

    if (normalizedValue.length > 2) {
      const newTimeout = setTimeout(() => {
        fetchSearchResults(normalizedValue, activeTab);
      }, 500);
      setSearchTimeout(newTimeout);
    } else if (value.length === 0) {
      clearSearch();
    }
  };

  const animationTimeline = useRef<GSAPTimeline>();

  const animateSearchResults = useCallback(() => {
    gsap.fromTo(".movies.scroll-container",
      { opacity: 0 },
      { 
        opacity: 1,
        duration: 0.2,
        clearProps: "all"
      }
    );
  }, []);

  const fetchSearchResults = async (term: string, contentType: TabName) => {
    const normalizedTerm = normalizeSearchTerm(term);
    if (normalizedTerm.length < 3) return;

    setResourceIsLoading(true);
    setSearchMade(true);

    // Blur the search input
    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }

    const category = contentType.toLowerCase() as ContentType;
    const cachedSearches = getCachedSearches(category);

    sessionStorage.setItem("searchTerm", normalizedTerm);
    sessionStorage.setItem("activeTab", contentType);

    try {
      if (cachedSearches[normalizedTerm]) {
        const newSearchResults = { ...searchResults, [category]: cachedSearches[normalizedTerm] };
        setSearchResults(newSearchResults);
        sessionStorage.setItem(getSearchResultsKey(), JSON.stringify(newSearchResults));
        animateSearchResults();
        return;
      }

      let results: any;
      switch (contentType) {
        case "Movies":
          results = await searchMoviesWithCast(normalizedTerm);
          break;
        case "Series":
          results = await searchSeriesWithCast(normalizedTerm);
          break;
        case "Animes":
          results = await searchAnimesWithCast(normalizedTerm);
          break;
        case "Cartoons":
          results = await searchCartoonsWithCast(normalizedTerm);
          break;
        case "Actors":
          results = await searchActorsFilmography(normalizedTerm);
          break;
      }
      const newSearchResults = { ...searchResults, [category]: results };
      setSearchResults(newSearchResults);
      sessionStorage.setItem(getSearchResultsKey(), JSON.stringify(newSearchResults));
      addCachedSearch(category, normalizedTerm, results);
      animateSearchResults();
    } catch (error) {
      Sentry.captureException(error);
      toast.error(`Error searching for ${contentType.toLowerCase()}`);
    } finally {
      setResourceIsLoading(false);
    }
  };

  const handleTabClick = (tab: TabName) => {
    setActiveTab(tab);
    sessionStorage.setItem("activeTab", tab);
    if (searchTerm) {
      fetchSearchResults(searchTerm, tab);
    }
  };


  const renderContent = () => {
    const content = searchMade ? searchResults[activeTab.toLowerCase() as keyof ContentResults] : popularContent[activeTab.toLowerCase() as keyof ContentResults];

    if (content.length === 0 && !searchMade) {
      return <p style={{ color: "white" }}>Loading {activeTab.toLowerCase()}...</p>;
    }

    if (content.length === 0 && searchMade) {
      return <p style={{ color: "white" }}>No results found</p>;
    }

    if (activeTab.toLowerCase() == "actors") {
      return <MovieDisplay items={content} displayMode={searchMade ? "search" : "trending"} type="actor" />;
    } else {
      return <MovieDisplay items={content} displayMode={searchMade ? "search" : "trending"} type="movie" />;
    }
  };

  const labelText = useMemo(() => {
    const totalResults = Object.values(searchResults).flat().length;
    if (totalResults === 0) {
      switch (activeTab) {
        case "Movies":
          return "Trending Movies";
        case "Series":
          return "Trending Series";
        case "Animes":
          return "Trending Anime";
        default:
          return "Trending";
      }
    } else {
      return `${activeTab} Results`;
    }
  }, [searchResults, activeTab]);

  const modalContext = useContext(ModalContext);

  const handleModalOpen = () => {
    if (modalContext && modalContext.openModal) {
      modalContext.openModal();
    } else {
      console.error("Modal context or openModal function is not available");
    }
  };

  // Optimize initial render sequence
  useEffect(() => {
    const tl = gsap.timeline({
      defaults: {
        ease: "power2.out",
        duration: 0.4
      }
    });

    // Logo should be visible for brand recognition (1s)
    tl.fromTo(".logo-container", 
      { opacity: 0 },
      { opacity: 1, duration: 0.3 })
      .to(".logo-container", { delay: 0.7 }) // Hold for visual recall
      // Subtle fade-in for main UI elements
      .fromTo(".search-bar", 
        { opacity: 0, y: 10 },
        { opacity: 1, y: 0 },
        "-=0.1")
      .fromTo(".tab-container",
        { opacity: 0 },
        { opacity: 1 },
        "-=0.2");

    // Proper cleanup function
    return () => {
      tl.kill();
      return void 0;
    };
  }, []);

  useEffect(() => {
    const initializeSearch = async () => {
      // Wait for popular content to be loaded
      if (Object.values(popularContent).some(arr => arr.length > 0)) {
        sessionStorage.setItem('searchComponentReady', 'true');
      }
    };

    initializeSearch();

    return () => {
      sessionStorage.removeItem('searchComponentReady');
    };
  }, [popularContent]);

  return (
    <>
      <Seo
        title={"Search - Scenextras"}
        description={"Search your favourite movies and characters."}
        url={`https://chat.scenextras.com/`}
        image={"https://framerusercontent.com/images/mvyirly4lm7ajnERA4s7OwV59Nw.png"}
      />


      {isResourceLoading && <Loading />}


      <div className="header-container">
        <div className="logo-container">
          <img 
            alt="full-logo" 
            className="full-logo-inline" 
            src="/full_logo.svg"
            style={{ willChange: 'opacity' }} // Optimize paint
          />
        </div>
        {props.user && props.user.isPremium !== "premium" && (
          <div className="upgrade-container">
            <button className="upgrade-button" onClick={handleModalOpen}>
              <span className="upgrade-button-text">Get MAX</span>
            </button>
          </div>
        )}
      </div>


      {/* Simplified character carousel mounting */}
      {characterCarousel && <CharacterCarousel cast={characterCarousel} />}
      <div
        ref={searchBarRef}
        className="search-bar"
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          borderRadius: "30px",
          willChange: "opacity, transform" // Optimize paint
        }}
      >
        <input
          type="text"
          placeholder=""
          className="search-input lightsweep-effect"
          value={searchTerm}
          ref={searchInputRef}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              fetchSearchResults(searchTerm, activeTab);
            }
          }}
          onChange={handleSearchInputChange}
          style={{
            boxShadow: "none",
            outline: "none",
            filter: "none",
            WebkitAppearance: "none",
            backgroundColor: "transparent",
            border: "none",
            color: "white",
            flex: 1,
            marginLeft: "10px"
          }}
        />
        <CameraCaptureSearch />
        <div className="input-buttons">
          <button
            className={`search-button ${isMacBook ? "search-button-mac" : ""}`}
            onClick={() => fetchSearchResults(normalizeSearchTerm(searchTerm), activeTab)}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
          <GeneralChat />
          {searchTerm && (
            <button
              className="clear-search-button"
              onClick={clearSearch}
              title="Clear search"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
      </div>
      <div
        className="tab-container"
        style={{
          transform: `translateX(${tabOffset}%)`,
          display: "flex",
          flexDirection: "row"
        }}
        ref={tabsRef} // Attach ref to tabs container
      >
        <div
          className={`tab ${activeTab === "Movies" ? "active" : ""}`}
          onClick={() => handleTabClick("Movies")}
        >
          Movies
        </div>
        <div
          className={`tab ${activeTab === "Series" ? "active" : ""}`}
          onClick={() => handleTabClick("Series")}
        >
          Series
        </div>
        <div
          className={`tab ${activeTab === "Animes" ? "active" : ""}`}
          onClick={() => handleTabClick("Animes")}
        >
          Anime
        </div>
        <div className={`tab ${activeTab === "Cartoons" ? "active" : ""}`}
             onClick={() => handleTabClick("Cartoons")}
        >
          Cartoons
        </div>
        <div className={`tab ${activeTab === "Actors" ? "active" : ""}`}
             onClick={() => handleTabClick("Actors")}
        >
          Actors
        </div>
        {/*<div*/}
        {/*  className={`tab ${activeTab === "Characters" ? "active" : ""}`}*/}
        {/*  onClick={() => handleTabClick("Characters")}*/}
        {/*>*/}
        {/*  Actors*/}
        {/*</div>*/}
      </div>
      <label className="trending mb-0">
        {labelText}
      </label>
      <div className="movies scroll-container">
        <div ref={containerRef} onMouseDown={onDragStart} className="search-movies item">
          {renderContent()}
        </div>
      </div>
    </>
  );
};
export default Search;
